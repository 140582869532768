import React, { useState, useEffect } from "react"

import "./theme.scss"

interface Props {
  className?: string
  animationTime?: number
  animationDelay?: number
  barBg?: string
  startPosition?: number
  endPosition?: number
  isAnimatedValue?: boolean
  isValue?: boolean
  easingFunction?: string
  segments?: { name: string }[]
  progressBarStyle?: {}
  segmentsStyle?: {}
  segmentStyle?: {}
  valueStyle?: {}
  barStyle?: {}
  barInnerStyle?: {}
}

export default ({
  className = "",
  animationTime = 2000,
  animationDelay = 200,
  barBg = "linear-gradient(45deg, #5b41f2, #AB49DD)",
  startPosition = 0,
  endPosition = 100,
  segments = [
    { name: "1 year" },
    { name: "2 years" },
    { name: "3 years" },
    { name: "4 years" },
    { name: "5 years" },
    { name: "6 years" },
    { name: "7 years" },
  ],
  isAnimatedValue = true,
  isValue = false,
  easingFunction = "ease",
  progressBarStyle = {},
  segmentsStyle = {},
  segmentStyle = {},
  valueStyle = {},
  barStyle = {},
  barInnerStyle = {},
}: Props) => {
  // States
  const [completed, setCompleted] = useState(0)
  const [barValue, setbarValue] = useState(0)

  // componentDidMount hook
  useEffect(() => {
    const iterationTime = Math.round(animationTime / endPosition)

    setTimeout(() => {
      const barValueCounter = setInterval(() => {
        setbarValue(prev => {
          if (prev + 1 === endPosition) {
            clearInterval(barValueCounter)
          }
          return prev + 1
        })
      }, iterationTime)
    }, animationDelay)

    setTimeout(
      () => setCompleted(() => endPosition - startPosition),
      animationDelay
    )
  }, [])

  // Render section
  return (
    <div
      className={`progress-bar ${className}`}
      style={{ ...progressBarStyle }}
    >
      <div className={"progress-bar__bar"} style={{ ...barStyle }}>
        <div
          className={"progress-bar__bar-segments"}
          style={{ ...segmentsStyle }}
        >
          {segments.map(segment => {
            return (
              <span
                className={"progress-bar__bar-segment"}
                key={segment.name}
                style={{ ...segmentStyle }}
              >
                <span className={"progress-bar__bar-segment-tip"}>
                  {segment.name}
                </span>
              </span>
            )
          })}
        </div>
        <div
          className={"progress-bar__bar-inner"}
          style={{
            ...barInnerStyle,
            background: barBg,
            width: `${completed}% `,
            transition: `${Math.round(
              animationTime / 1000
            )}s ${easingFunction}`,
            left: `${startPosition}%`,
          }}
        >
          {isValue ? (
            <div
              className={"progress-bar__bar-value"}
              style={{ ...valueStyle }}
            >
              {isAnimatedValue ? `${barValue}%` : `${endPosition}%`}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
