import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { removeTags } from '~c/utils/base'

import "./breadcrumbs.scss"

interface Props {
  location: {
    hash: string
    host: string
    hostname: string
    href: string
    key: string
    origin: string
    pathname: string
    port: string
    protocol: string
    search: string
    state: string
  }
  parentClassName?: string
  childrenClassName?: string
}

interface Breadcrumbs {
  path: string
  title: string
}

interface Node {
  frontmatter: {
    slug: string
    title: string
  }
}

export default React.memo(
  ({ location, parentClassName = "", childrenClassName = "" }: Props) => {
    const home = { slug: "/", title: "Home" }
    const paths = location.pathname.match(/([a-zA-Z0-9_-]+)/g)

    const { nodes } = useStaticQuery(query).allMarkdownRemark

    const currentNode = nodes.filter((node: Node) => {
      const { frontmatter } = node
      return paths !== null ? frontmatter.slug === paths[paths.length - 1] : ""
    })

    const breadcrumbs: Breadcrumbs[] = []
    if (paths !== null) {
      paths.forEach(path => {
        breadcrumbs.push({
          path: "/" + path,
          title: path.charAt(0).toUpperCase() + path.slice(1),
        })
      })
    }

    let title = ""
    if (currentNode[0]) {
      const { frontmatter } = currentNode[0]
      title = frontmatter.title
    } else {
      title = location.pathname.replace(/\/+/g, "")
      title = title.charAt(0).toUpperCase() + title.slice(1)
    }

    return (
      <nav
        className={`breadcrumbs ${parentClassName}`}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        <span
          className={`breadcrumb ${childrenClassName}`}
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <a href={home.slug} itemProp="item">
            <span itemProp="name">{home.title}</span>
            <meta itemProp="position" content="1" />
          </a>
        </span>
        {breadcrumbs.map((breadcrumb, i, arr) => {
          if (arr.length >= 2) {
            if (arr.length - 1 !== i) {
              return (
                <span
                  className={`breadcrumb ${childrenClassName}`}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                  key={breadcrumb.path}
                >
                  <a href={breadcrumb.path}>
                    <span itemProp="name">
                      {breadcrumb.title.replace(/<[^>]*>?/gm, "").trim()}
                    </span>
                    <meta itemProp="position" content={`${i + 2}`} />
                  </a>
                </span>
              )
            }
          }
        })}
        <span className={`breadcrumb ${childrenClassName}`}>
          { removeTags(title) }
        </span>
      </nav>
    )
  }
)

export const query = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
  }
`
